import React, { useState, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useIMask } from "react-imask";
import { useDispatch, useSelector } from "react-redux";
import { apiUrl } from "../../../data/constants";
import { isValidExpirationDate, formatPrice } from "../../../helpers/index";

// import { postBooking } from "../../../redux/thunks/coursesThunk";
// import { Loader } from "../../../components/ui/Loader"

export const PaymentDetails = ({ prevHandler, nextHandler }) => {
  const [params] = useSearchParams();
  const parameters = ["domain", "utm_source", "utm_medium"];
  let utmParams = "";

  parameters.map((paramName) => {
    if (params.get(paramName)) {
      const paramValue = params.get(paramName);
      utmParams += "&" + paramName + "=" + paramValue;
    }
  });

  const maskOptions = {
    cardNumber: {
      mask: "0000 0000 0000 0000",
      lazy: false,
      placeholderChar: "-",
    },
    expirationDate: {
      mask: "00 / 00",
      lazy: false,
      placeholderChar: "-",
    },
    cardCvv: {
      mask: "000",
      lazy: false,
      placeholderChar: "-",
    },
  };

  const dispatch = useDispatch();
  const [loading, setLoading] = useState();
  const [isExpired, setIsExpired] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const { internal_id } = useParams();
  const learners = useSelector((state) => state.courses.learners);
  console.log("learners:", learners);
  const selectedCourse = useSelector((state) => state.courses.selectedCourse);

  const tags = useSelector((state) => state.courses.tags);
  const billingData = useSelector((state) => state.courses.billingData);
  const { access_token, instance_url } = useSelector((state) => state.courses);

  const expirationDateMask = useIMask(maskOptions.expirationDate);
  const cardNumberMask = useIMask(maskOptions.cardNumber);
  const cardCvvMask = useIMask(maskOptions.cardCvv);

  const inputFocus = (inputElem) => {
    setTimeout(() => {
      if (!inputElem?.value?.length || inputElem?.value?.includes("-")) {
        inputElem?.setSelectionRange(0, 0);
      }
      inputElem?.focus();
    }, 100);
  };

  useEffect(() => {
    setErrorMessage(null);
    setTimeout(() => {
      if (
        cardNumberMask.unmaskedValue.length &&
        !cardNumberMask.value.includes("-")
      ) {
        inputFocus(expirationDateMask.ref.current);
      }
    }, 50);
  }, [cardNumberMask.value]);

  useEffect(() => {
    setErrorMessage(null);
    setTimeout(() => {
      if (
        cardNumberMask.unmaskedValue.length &&
        !expirationDateMask.value.includes("-")
      ) {
        inputFocus(cardCvvMask.ref.current);
      }
    }, 50);
    setIsExpired(!isValidExpirationDate(expirationDateMask.unmaskedValue));
  }, [expirationDateMask.value]);
  useEffect(() => {
    setErrorMessage(null);
  }, [cardCvvMask.value]);
  useEffect(() => {
    setTimeout(() => {
      inputFocus(cardNumberMask.ref.current);
    }, 150);
    setTimeout(() => {
      inputFocus(cardNumberMask.ref.current);
    }, 200);
  }, []);

  const submitPay = async (e) => {
    e.preventDefault();

    if (loading) {
      return false;
    }

    setErrorMessage(null);
    setLoading(true);

    let rp = apiUrl;

    function confirm3ds(params) {
      let url_params = "/" + btoa(JSON.stringify(params));
      return new Promise((res) => {
        let iframe = document.createElement("iframe");
        iframe.classList.add("iframe-3ds");
        iframe.src = rp + `3ds` + url_params;

        window.addEventListener("message", async function messageHandler(e) {
          if (e.data) {
            if (e.data.iframe_3ds) {
              window.removeEventListener("message", messageHandler);
              let payment_status = await fetch(
                `${rp}check-payment-status/${params.trans_id}`,
                {
                  headers: {
                    "Content-Type": "application/json",
                  },
                  method: "GET",
                }
              ).then((x) => x.json());
              document.querySelector(".iframe-3ds").remove();
              res(payment_status);
            }
          }
        });
        document.body.append(iframe);
      });
    }

    async function getSesKey() {
      return await fetch(`${rp}payments/makeMSK`, {
        headers: {
          "Content-Type": "application/json",
        },
        method: "GET",
      }).then((x) => x.json());
    }

    async function submitPayment(ses_key, card_id, other_data = {}) {
      const users = learners.map((learner) => ({
        ...learner,
        ...billingData,
      }));
      console.log(users);

      let data = {
        ses_key: ses_key,
        card_id: card_id,
        users: users,
        _other: {
          notificationURL: rp + "3ds",
          course_id: internal_id,
          price: (
            selectedCourse.accept_deposit === 1
              ? +selectedCourse.price_deposite
              : +selectedCourse.price_online
          )
            ? selectedCourse.accept_deposit === 1
              ? +selectedCourse.price_deposite
              : +selectedCourse.price_online
            : 0, //TODO price
          course_title: selectedCourse.name,
          ...tags,
        },
      };

      data = { ...data, ...other_data };

      let resp = await fetch(`${rp}payments/submit`, {
        headers: {
          Accept: "*/*",
          "content-type": "application/json",
        },
        body: JSON.stringify(data),
        method: "POST",
      }).then((r) => r.json());

      console.log("submit resp is", resp);
      let hash = btoa(JSON.stringify(data)) + utmParams;
      if (resp.required_3ds) {
        let payment_status = await confirm3ds(resp.params_3ds);
        // alert("payment success? : "+payment_status.success);
        if (payment_status.success) {
          setLoading(false);
          // handleNavigateNextPage();
          window.location.replace(
            "https://www.skillstg.co.uk/booking-confirmation/?hash=" + hash
          );
        } else {
          setErrorMessage("Payment failed");
        }
      } else {
        if (resp.success) {
          cardNumberMask.setUnmaskedValue("");
          expirationDateMask.setUnmaskedValue("");
          cardCvvMask.setUnmaskedValue("");
          setLoading(false);

          window.location.replace(
            "https://www.skillstg.co.uk/booking-confirmation/?hash=" + hash
          );
        } else {
          setErrorMessage("Payment failed");
        }
      }
    }

    let { ses_key } = await getSesKey();
    let result = await new Promise(function (res, rej) {
      window
        .sagepayOwnForm({ merchantSessionKey: ses_key })
        .tokeniseCardDetails({
          cardDetails: {
            cardholderName:
              learners[0]?.firstName + " " + learners[0]?.lastName,
            cardNumber: cardNumberMask.unmaskedValue.split(" ").join(""),
            expiryDate: expirationDateMask.unmaskedValue.split(" / ").join(""),
            securityCode: cardCvvMask.unmaskedValue,
          },
          onTokenised: function (result) {
            res(result);
          },
        });
    });

    console.log("result", result);

    if (!result.success) {
      setLoading(false);
      setErrorMessage("ERROR: " + result.errors[0].message);
      return false;
    }
    let other_data = {
      wwidth: window.innerWidth,
      wheight: window.innerWidth,
      tz: new Date().getTimezoneOffset(),
    };

    await submitPayment(ses_key, result.cardIdentifier, other_data);

    setLoading(false);
  };

  return (
    <form onSubmit={submitPay}>
      <h3 className="mb-30">Payment Details</h3>
      <div className="row gutters-16 mb-30">
        <div className="col-12">
          <div className="form-group">
            <label className="form-group-title">
              Card Number<span className="color-danger ms-1">*</span>
            </label>
            <input
              ref={cardNumberMask.ref}
              className="input"
              type="text"
              autoFocus
            />
          </div>
        </div>
        <div className="col-lg-7">
          <div className="row gutters-10">
            <div className="col-md-7">
              <div className="form-group">
                <label className="form-group-title">
                  Expiration Date<span className="color-danger ms-1">*</span>
                </label>
                <input
                  ref={expirationDateMask.ref}
                  className={`input ${isExpired ? "error" : ""}`}
                  type="text"
                />
                {isExpired && (
                  <span className="color-danger">Card expired</span>
                )}
              </div>
            </div>
            <div className="col-md-5">
              <div className="form-group">
                <label className="form-group-title">
                  CVC<span className="color-danger ms-1">*</span>
                </label>
                <input ref={cardCvvMask.ref} className="input" type="text" />
              </div>
            </div>
          </div>
          {errorMessage ? (
            <div className="mb-30 mt-10">
              <p style={{ color: "red" }}>{errorMessage}</p>
            </div>
          ) : null}
        </div>
      </div>
      <div className="page-action d-flex align-items-center justify-content-between">
        <button
          onClick={prevHandler}
          className="btn btn--outline-secondary btn--md radius-5 ttu min-w-120"
        >
          Back
        </button>
        <button
          className={`btn btn--success btn--md radius-5 ttu min-w-240 ${
            loading || isExpired ? "disabled" : ""
          }`}
          style={{ minHeight: "46px", opacity: isExpired ? "0.7" : "1" }}
          type="submit"
        >
          {loading ? (
            "Loading..."
          ) : (
            <>
              PAY £
              {formatPrice(
                (selectedCourse.accept_deposit === 1
                  ? +selectedCourse.price_deposite.toFixed(2)
                  : +selectedCourse.price_online.toFixed(2)) *
                  (learners.length > 1 ? learners.length - 1 : learners.length)
              )}
            </>
          )}
        </button>
      </div>
    </form>
  );
};
